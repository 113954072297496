﻿import Splide from '@splidejs/splide';
import { localBanner, banner } from './utilidades/banner'
// import { elementoFlutuante } from './utilidades/elementoFlutuante';


const homeApp = function () {

    const _ativaInput = () => {
        const iconesInput = document.querySelectorAll('.icone-input-pesquisa').forEach(icone => {
            icone.addEventListener("click", () => {
                icone.nextElementSibling.focus();
            })
        })
    }



    const _headerScroll = () => {
        const headerCandidato = document.getElementById('header-candidato');
        const sectionBannerHome = document.querySelector('.section-banner-home h1');

        // headerCandidato.classList.remove('sticky-top');
        headerCandidato.classList.remove('bg-white');

        function callback(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    headerCandidato.classList.add('header-candidato-customizado');
                    headerCandidato.classList.remove('bg-white');
                } else {
                    headerCandidato.classList.add('bg-white');
                    headerCandidato.classList.remove('header-candidato-customizado');
                }
            })
        }

        const observer = new IntersectionObserver(callback, { root: null, threshold: .9, rootMargin: '-70px' });
        observer.observe(sectionBannerHome);
    }



    const _init = function () {
        banner.renderizar('banner-home', localBanner.HOME, true);
        _ativaInput();
        _headerScroll();
        //elementoFlutuante('.elemento-parallax', 1);

        const splideOptionsVagas = {
            type: 'loop',
            pagination: false,
            gap: '2em',
            width: '100%',
            perPage: 4,
            lazyLoad: 'nearby',
            padding: '5em',
            breakpoints: {
                1199: {
                    perPage: 3,
                },
                992: {
                    perPage: 2,
                },
                767: {
                    arrows: false,
                    perPage: 1,
                    padding: '7em',
                },
                425: {
                    padding: '2em',
                }
            }
        }

        const splideVagas = new Splide('#splide-cards-oportunidades', splideOptionsVagas);
        splideVagas.mount();

        new Splide('.splide-parceiros', {
            type: 'loop',
            arrows: false,
            pagination: false,
            gap: '2em',
            width: '100%',
            autoWidth: true,
            breakpoints: {
                2560: {
                    destroy: true,
                },
                992: {
                    destroy: false,
                    padding: '2em',
                    perPage: 1,
                }
            }
        }).mount();

        new Splide('.splide-nectar', {
            type: 'loop',
            arrows: false,
            pagination: false,
            gap: '1em',
            width: '100%',
            breakpoints: {
                //2560: {
                //    destroy: true,
                //},
                1199: {
                    perPage: 3,
                },
                768: {
                    destroy: false,
                    padding: '2em',
                    perPage: 2,
                },
                576: {
                    perPage: 1,
                }
            }
        }).mount();
    }

    return _init();

}
homeApp();

//new Splide('#splide-cards-oportunidades', {
//    type: 'loop',
//    pagination: false,
//    gap: '2em',
//    width: '100%',
//    perPage: 4,
//    padding: '5em',
//    breakpoints: {
//        1024: {
//            perPage: 3,
//        },
//        768: {
//            perPage: 2,
//        },
//        425: {
//            arrows: false,
//            perPage: 1,
//        }
//    }

//}).mount();

//new Splide('.splide-parceiros', {
//    type: 'loop',
//    arrows: false,
//    pagination: false,
//    gap: '2em',
//    width: '100%',
//    autoWidth: true,
//    breakpoints: {
//        2560: {
//            destroy: true,
//        },
//        425: {
//            padding: '5em',
//            perPage: 1,
//            destroy: false,
//        }
//    }
//}).mount();

//new Splide('.splide-nectar', {
//    type: 'loop',
//    arrows: false,
//    pagination: false,
//    gap: '1em',
//    width: '100%',
//    breakpoints: {
//        2560: {
//            destroy: true,
//        },
//        768: {
//            destroy: false,
//            padding: '2em',
//            perPage: 2,
//        },
//        576: {
//            perPage: 1,
//        }
//    }
//}).mount();
