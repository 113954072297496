﻿export const localBanner = {
    HOME: 1,
    VAGA_BUSCA: 2,
    VAGA_DETALHES: 3,
    PAINEL: 4,
    EMPLOYER_PAGE: 5,
};

export const banner = {
    async renderizar(containerId, local, exibirMobile = true) {
        const container = document.getElementById(containerId);

        if (!container) {
            console.error('[banner]: não foi encontrado nenhuma div com o id especificado para renderizar o banner!');
            return;
        }

        try {
            const banner = await this._buscarBanner(local);
            console.log('banner', banner)
            if (banner.sucesso && banner) {
                this._renderizarHTML(container, banner, local, exibirMobile);
            } else {
                // this._renderizarBannerPadrao(container);
            }
        } catch (error) {
            console.error('Erro ao carregar banner:', error);
            // this._renderizarBannerPadrao(container);
        }
    },

    async _buscarBanner(local) {
        try {
            const response = await fetch(`/Home/GetBanner?local=${local}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return await response.json();
        } catch (error) {
            console.error('Erro ao buscar banner:', error);
            return { sucesso: false };
        }
    },

    _renderizarHTML(container, banner, local, exibirMobile) {
        const redirectUrl = `/Home/RedirectBanner?id=${banner.ID}&local=${local}`;

        //const botaoFecharHTML = botaoFechar
        //    ? `
        //    <button class="btn-close position-absolute top-0 end-0 m-2" aria-label="Fechar" 
        //            style="background-color: rgba(0,0,0,0.5); color: white;">
        //    </button>`
        //    : '';

        const html = `
        <div class="banner-wrapper position-relative">
            
            <a href="${redirectUrl}" target="_blank" data-banner-id="${banner.ID}" 
               class="${exibirMobile ? `d-flex` : `d-none d-md-flex`} justify-content-center">
                <picture class="col-12 text-center">
                    <source media="(max-width: 767px)" 
                            srcset="https://storage.empregare.com/banners/${banner.ArquivoMobile}" 
                            class="img-fluid style="border-radius: 3px;">
                    <img src="https://storage.empregare.com/banners/${banner.ArquivoDesktop}" 
                         alt="${banner.Titulo}" 
                         class="img-fluid" style="border-radius: 3px;"/>
                </picture>
            </a>
        </div>`;

        container.innerHTML = html;

        //const botaoFecharElemento = container.querySelector('.btn-close');
        //if (botaoFecharElemento) {
        //    botaoFecharElemento.addEventListener('click', () => {
        //        this.ocultarBanner(banner.ID);
        //    });
        //}

        const linkElement = container.querySelector('a');
        if (linkElement) {
            linkElement.style.transition = 'opacity 0.3s ease-in-out';

            linkElement.addEventListener('mouseover', () => {
                linkElement.style.opacity = '0.8';
            });

            linkElement.addEventListener('mouseout', () => {
                linkElement.style.opacity = '1';
            });
        }
    },

    //async ocultarBanner(bannerID) {
    //    try {
    //        const response = await fetch('/Company/Banner/OcultarBanner', {
    //            method: 'POST',
    //            headers: {
    //                'Content-Type': 'application/json',
    //            },
    //            body: JSON.stringify({ bannerID: bannerID }),
    //        });

    //        const result = await response.json();

    //        if (result.sucesso) {
    //            const bannerElement = document.querySelector(`[data-banner-id="${bannerID}"]`);
    //            if (bannerElement) {
    //                bannerElement.closest('.banner-wrapper').remove();
    //            }
    //        }
    //    } catch (error) {
    //        console.error('Erro ao ocultar banner:', error);
    //    }
    //},

    //_renderizarBannerPadrao(container) {
    //    const html = `
    //        <div class="banner-container">
    //            <a href="#" target="_blank">
    //                <img src="https://www.empregare.com/assetsV2/common/images/global/logo.png" 
    //                     alt="Banner Estático" />
    //            </a>
    //        </div>
    //    `;
    //
    //    container.innerHTML = html;
    //}
};

